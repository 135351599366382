<template>
    <v-chip small :color="status.color" outlined  >
        <v-icon class="mx-1"> {{ status.icon }} </v-icon>
        {{'sms.'+props.value | t }}
    </v-chip>
</template>
<script>
 
export default {
    props: {
        props:Object,
        field:Object
    },
    computed: {
        status () {
            const all = {
                not_sent: {
                    color: '',
                    icon: 'mdi-send-outline'
                },
                accepted: {
                    color: 'primary',
                    icon: 'mdi-check'
                },
                queued: {
                    color: 'warning',
                    icon: 'mdi-send-clock-outline'
                },
                sent: {
                    color: 'info',
                    icon: 'mdi-send'
                },
                failed: {
                    color: 'error',
                    icon: 'mdi-close'
                },
                delivered: {
                    color: 'success',
                    icon: 'mdi-send-check'
                },
                undelivered: {
                    color: 'error',
                    icon: 'mdi-close'
                }
            }
            return all[this.props.value]
        }
    }
}
</script>
<style scoped>
.chips {
    height: 16px;
    width: 16px;
    padding: 0;
    margin: 0;
    border: 2px solid red;
}
</style>